import pino from 'pino';

const prettyPrintLogs = import.meta.env.VITE_PRETTY_PRINT_LOGS === 'true';

const devConfig = {
  transport: {
    target: 'pino-pretty',
    options: {
      colorize: true,
      translateTime: 'SYS:standard',
    },
  },
};

const prodConfig = {
  level: 'error',
};

const logger = pino(prettyPrintLogs ? devConfig : prodConfig);

export default logger;
